import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home";
import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout/logout.component";
import { RegisterComponent } from "./register/register.component";
import { ProfileComponent } from "./profile";
import { InboxComponent } from "./inbox";
import { SettingsComponent } from "./settings";
import { RealestateComponent } from "./realestate";
import { FaqComponent } from "./faq";
import { ForgotComponent } from "./forgot/forgot.component";
import { PaymentsComponent } from "./payments/payments.component";
import { AuthGuard } from "./_guards/auth.guard";
import { TermsComponent } from "./terms/terms.component";
import { UsersComponent } from "./users/users.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { DownloadZipComponent } from "./download-zip-module/download-zip.component";
import { NgModule } from "@angular/core";
import { FloortourComponent } from "./floortour/floortour.component";
import { AccountingPositionsComponent } from "./accounting-positions/accounting-positions.component";
import { DiscountsComponent } from "./discounts/discounts.component";
import { CompaniesComponent } from "@app/companies/companies.component";
import { InvoicesComponent } from "./invoices/invoices.component";
import { AccountingPositionResolverService } from "./_services/accounting-positions-resolver.service";
import { CompaniesResolverService } from "./_services/companies-resolver.service";
import { WebsocketsService } from "@app/_services/websockets.service";
import { ProductBundlesComponent } from "./product-bundles/product-bundles.component";
import { ServicesResolverService } from "./_services/services-resolver.service";
import { ResetPasswordComponent } from "@app/reset-password/reset-password.component";
import { AnalyticsComponent } from "@app/analytics/analytics.component";
import { LoginWithTokenComponent } from "@app/login-with-token/login-with-token.component";
import { OrderTypeResponsiblesConfigResolverService } from "./_services/order-type-responsibles-config-resolver.service";
import { UsersLogicService } from "./_services/users-logic.service";
import { MyRealEstatesResolverService } from "@app/_services/my-real-estates-resolver.service";
import { PackagesDeliveryTimesConfigResolverService } from "./_services/packages-delivery-times-config-resolver.service";
import { LandingPageTrackingConfigResolverService } from "./_services/landing-page-tracking-config-resolver.service";
import { StandAloneFeedbackToolComponent } from "./stand-alone-feedback-tool/stand-alone-feedback-tool.component";
import { OnOfficeActivationComponent } from "@app/on-office-activation/on-office-activation.component";
import { ReportingPresetsConfigResolverService } from "./_services/reporting-presets-config-resolver.service";
import { ResetSessionComponent } from "@app/reset-session/reset-session.component";
import { NewsSlidesManagerComponent } from "@app/news-slides-manager/news-slides-manager.component";
import { NewsSlidesLogicService } from "@app/_services/news-slides-logic.service";

const appRoutes: Routes = [
  { path: "home", component: HomeComponent, canActivate: [AuthGuard] },
  { path: "login", component: LoginComponent },
  { path: "login-with-token", component: LoginWithTokenComponent },
  { path: "logout", component: LogoutComponent },
  { path: "reset-session", component: ResetSessionComponent },
  { path: "register", component: RegisterComponent, resolve: [LandingPageTrackingConfigResolverService] },
  { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
  {
    path: "analytics",
    component: AnalyticsComponent,
    canActivate: [AuthGuard],
    resolve: [WebsocketsService, CompaniesResolverService, ReportingPresetsConfigResolverService],
    data: { roles: ["Administrator"] },
  },
  {
    path: "discounts",
    component: DiscountsComponent,
    canActivate: [AuthGuard],
    data: { roles: ["Administrator"] },
    resolve: [CompaniesResolverService, AccountingPositionResolverService, ServicesResolverService],
  },
  {
    path: "product-bundles",
    component: ProductBundlesComponent,
    canActivate: [AuthGuard],
    data: { roles: ["Administrator"] },
    resolve: [ServicesResolverService, AccountingPositionResolverService, CompaniesResolverService],
  },
  {
    path: "realestate",
    component: RealestateComponent,
    canActivate: [AuthGuard],
    resolve: [MyRealEstatesResolverService],
  },
  { path: "faq", component: FaqComponent, canActivate: [AuthGuard] },
  { path: "inbox", component: InboxComponent, canActivate: [AuthGuard] },
  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [AuthGuard],
    resolve: [
      AccountingPositionResolverService,
      CompaniesResolverService,
      UsersLogicService,
      OrderTypeResponsiblesConfigResolverService,
      PackagesDeliveryTimesConfigResolverService,
      LandingPageTrackingConfigResolverService,
    ],
  },
  { path: "forgot", component: ForgotComponent, canActivate: [AuthGuard] },
  { path: "reset-password", component: ResetPasswordComponent, canActivate: [AuthGuard] },
  {
    path: "payments",
    component: PaymentsComponent,
    canActivate: [AuthGuard],
    resolve: [UsersLogicService, CompaniesResolverService],
    data: { roles: ["Administrator"] },
  },
  { path: "terms", component: TermsComponent },
  {
    path: "users",
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: { roles: ["Administrator"] },
    resolve: [CompaniesResolverService],
  },
  { path: "companies", component: CompaniesComponent, canActivate: [AuthGuard] },
  {
    path: "accounting-positions",
    component: AccountingPositionsComponent,
    canActivate: [AuthGuard],
    data: { roles: ["Administrator"] },
    resolve: [CompaniesResolverService],
  },
  {
    path: "invoices",
    component: InvoicesComponent,
    canActivate: [AuthGuard],
  },
  { path: "invoices/:invoiceNumber", component: InvoicesComponent, canActivate: [AuthGuard] },
  { path: "changepassword", component: ChangePasswordComponent, canActivate: [AuthGuard] },
  {
    path: "createorder",
    loadChildren: () => import("./order-creation-module/order-creation.module").then((m) => m.OrderCreationModule),
    canActivate: [AuthGuard],
  },
  {
    path: "management-board",
    loadChildren: () => import("./managment-board/managment-board.module").then((m) => m.ManagmentBoardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "services",
    loadChildren: () => import("./order-creation-module/order-creation.module").then((m) => m.OrderCreationModule),
    canActivate: [AuthGuard],
  },
  {
    path: "orderoverview",
    loadChildren: () => import("./orders-view-module/orders.module").then((m) => m.OrdersModule),
    canActivate: [AuthGuard],
    resolve: [WebsocketsService],
  },
  {
    path: "downloadzip",
    component: DownloadZipComponent,
  },
  {
    path: "imo360/:sid",
    component: FloortourComponent,
  },
  {
    path: "imo360",
    component: FloortourComponent,
  },
  {
    path: "feedback-tool/:sid",
    component: StandAloneFeedbackToolComponent,
    canActivate: [AuthGuard],
  },
  { path: "onOffice-activation", component: OnOfficeActivationComponent, canActivate: [AuthGuard] },
  {
    path: "news-slides-management",
    component: NewsSlidesManagerComponent,
    canActivate: [AuthGuard],
    data: { roles: ["Administrator"] },
    resolve: [NewsSlidesLogicService],
  },
  /* {
        path: "suborder/:sid",
        component: OrderoverviewComponent,
        canActivate: [AuthGuard]
    }, */
  { path: "**", redirectTo: "login" },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { anchorScrolling: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
