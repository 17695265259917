<ng-container *ngIf="type === 'service'">
  <span *ngIf="(tile | anyCast).isAvailableBkbnService" class="badge bkbn available d-block position-absolute">
    <a class="link-orange-bold" [href]="CONSTANTS.BANNER_LINKS.IMO_BKBN" target="_blank">Bei bkbn.com verfügbar</a>
  </span>
  <span
    *ngIf="!(tile | anyCast).isAvailableBkbnService && (tile | anyCast).isUpcomingBkbnService"
    class="badge bkbn upcoming d-block position-absolute"
  >
    <span class="link-gray-bold user-select-none">Bald bei bkbn.com verfügbar</span>
  </span>
</ng-container>
<div
  class="cards position-relative"
  [matTooltip]="(tile | anyCast).tooltip"
  [matTooltipDisabled]="!(tile | anyCast).tooltip"
  [matTooltipPosition]="'below'"
  [ngClass]="{
    'service-card': type === 'service',
    'package-card': type === 'package',
    maxH40: type === 'package' && mini,
    'disabled-tile': (tile | anyCast).isDisabled
  }"
  (click)="onclick(tile)"
>
  <div
    class="card card-pricing card-raised tile my-2 d-flex flex-column justify-content-between"
    (click)="toggleHandler()"
    [ngClass]="{
      'selected-tile':
        (!mini &&
          (ols.selectedServices.indexOf((tile | anyCast).id) >= 0 ||
            ((tile | anyCast).productBundleData &&
              ols.selectedProductBundlesIds.indexOf((tile | anyCast).productBundleData.id) >= 0) ||
            ols.selectedPackageIds.indexOf(customPackageId) >= 0)) ||
        (mini && tile.package_key === ols.currentPackageId.value),
      'disabled-tile': (tile | anyCast).isDisabled,
      'service-tile': type === 'service',
      'package-tile': type === 'package',
      'fixed-tile': (tile | anyCast).isFixed,
      hoverFrame: mini
    }"
    [matTooltip]="
      (tile | anyCast).isDisabled === true && type === 'service' && (tile | anyCast).isAvailableBkbnService === true
        ? 'Klicken, um die Backbone-Plattform in einem neuen Tab zu öffnen'
        : null
    "
    [matTooltipPosition]="'above'"
  >
    <div class="discount row m-0" *ngIf="type === 'service' && !isOrderCreationDisabled">
      <div class="p-0 small">
        <span
          class="badge rabatt"
          [hidden]="!isDiscountAvailable"
          matTooltip="Rabattaktion verfügbar"
          [matTooltipPosition]="'above'"
        >
          <span class="rebatt"> Rabatt</span>
        </span>
      </div>
    </div>
    <div
      *ngIf="img_src?.split('.').pop() === 'svg'"
      [ngClass]="{
        'svg-container-package': type === 'package'
      }"
      class="m-auto"
    >
      <svg
        [ngClass]="{
          'tile-image-service': type === 'service',
          'tile-image-package': type === 'package',
          grayscale: (tile | anyCast).isDisabled,
          'color-green': (tile | anyCast).isDisabled,
          'bg-white': !(tile | anyCast).isDisabled
        }"
        class="card-img-modern"
      >
        <use
          [attr.xlink:href]="'../../assets/images/' + img_src"
          [attr.href]="'../../assets/images/' + img_src + '#Layer_1'"
        ></use>
      </svg>
    </div>
    <div *ngIf="img_src?.split('.').pop() !== 'svg'">
      <img
        [ngClass]="{
          'tile-image-service': type === 'service',
          'tile-image-package': type === 'package',
          grayscale: (tile | anyCast).isDisabled
        }"
        class="tile-image-package card-img-modern bg-white subcategory-image"
        src="../../assets/images/{{ img_src }}"
        alt="Tile"
      />
    </div>
    <div
      class="card-img-overlay pt-0 p-2 d-flex mb-1 justify-content-end flex-column"
      [ngClass]="{
        grayscale: (tile | anyCast).isDisabled === true,
        'service-overlay': type === 'service',
        'package-overlay': type === 'package' && !mini,
        'mini-package-overlay ': type === 'package' && mini
      }"
    >
      <div class="row m-0" *ngIf="type === 'package'">
        <div class="p-0 small">
          <span
            class="badge rabatt"
            [hidden]="isOrderCreationDisabled || !isDiscountAvailable"
            matTooltip="Rabattaktion verfügbar"
            [matTooltipPosition]="'above'"
          >
            <span class="rebatt"> Rabatt</span>
          </span>
        </div>
      </div>

      <div
        class="card-title text-left font-weight-500 font-size-noraml"
        [ngClass]="{
          'medium-font': mini,
          'mini-font': mini && (title?.length >= 22 || tile[titleField]?.length >= 22)
        }"
      >
        <b>{{ title || tile[titleField] }}</b>
      </div>
      <div *ngIf="!hideDetails && !mini" class="text-left">
        <div class="mb-0 font-size-noraml text-nowrap card-title">
          <span *ngIf="(tile | anyCast).price_pre_text" class="font-weight-500"
            >{{ (tile | anyCast).price_pre_text }}
          </span>
          <span
            *ngIf="type === 'package'"
            class="font-weight-500"
            [innerHTML]="ms.floatToString(price | addVat) + ' €'"
          ></span>
          <span *ngIf="type === 'package' && price" class="font-weight-light font-smaller">
            <ng-container *ngIf="!auth.myUserObservable.isPrivateIndividual"> zzgl.</ng-container>
            <ng-container *ngIf="auth.myUserObservable.isPrivateIndividual">
              inkl. {{ CONSTANTS.VAT_TAX_PERCENTAGE }}%</ng-container
            >
            MwSt.
          </span>
        </div>

        <div
          *ngIf="type === 'service' && (servicePrice || servicePrice === 0) && !isOrderCreationDisabled"
          class="mb-0 font-size-noraml card-title"
        >
          <span class="text-nowrap font-weight-500">ab {{ ms.floatToString(servicePrice | addVat) + " €" }}</span>
          <span class="font-weight-light font-smaller">
            <ng-container *ngIf="!auth.myUserObservable.isPrivateIndividual"> zzgl.</ng-container>
            <ng-container *ngIf="auth.myUserObservable.isPrivateIndividual">
              inkl. {{ CONSTANTS.VAT_TAX_PERCENTAGE }}%</ng-container
            >
            MwSt.
          </span>
        </div>
        <p *ngIf="(tile | anyCast).price_note && type !== 'package'">
          <small class="text-muted text-nowrap">{{ (tile | anyCast).price_note }}</small>
        </p>
      </div>
      <ng-container *ngIf="(tile | anyCast).delivery_time && !hideDetails && !mini">
        <div class="delivery-info">
          <div class="delivery"></div>
          <i class="fas fa-shipping-fast delivery-icon"></i>
          <div class="delivery-text">{{ (tile | anyCast).delivery_time }}</div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container
    *ngIf="
      ols.selectedServices.indexOf((tile | anyCast).id) >= 0 ||
      ((tile | anyCast).productBundleData &&
        ols.selectedProductBundlesIds.indexOf((tile | anyCast).productBundleData.id) >= 0) ||
      ols.selectedPackageIds.indexOf(customPackageId) >= 0
    "
  >
    <img
      class="selected-tick"
      [ngClass]="{
        'service-selected-tick': type === 'service',
        'package-selected-tick': type === 'package'
      }"
      (click)="toggleHandler()"
      *ngIf="!mini"
      src="../../assets/images/selectedCartIcon.png"
    />
  </ng-container>
</div>
